/**
 * @since 2024-01-20
 * @author Francesco Parrella
 * @maintainer Francesco Parrella
 * @copyright AlgoTraders, All rights reserved
 */

.panelLiveStats {
  .toggleDates {
    display: flex;
    flex-wrap: wrap;
    width: 700px;

    button {
      background-color: #2b2c31;
      color: white;
      border: none;
      height: 60px;
      width: 240px;
      flex-grow: 1;
      font-size: 14px;
      flex: 1;
    }

    button.Mui-selected {
      background-color: #2b2c31;
      color: #78bcfb;
    }

    button.Mui-selected:hover,
    button.Mui-selected:active,
    button:active,
    .panelReportButtonGroup button:hover {
      background-color: #2b2c31;
    }
  }
}
