/**
 * @since: 2023-9-6
 * @author: Mohammad Traboulsi
 * @maintainer: Mohammad Traboulsi
 * @copyright: AlgoTraders, All rights reserved
 */

.reset-button {
  margin-bottom: 20px;
  background-color: #78bcfb;
  border: none;
  color: #fff;
  text-decoration: #fff;
  font-weight: bold;
  border-radius: 3px;
  cursor: pointer;
}
:where(.css-dev-only-do-not-override-1mqg3i0).ant-btn-default:not(
    :disabled
  ):hover {
  color: #fff;
  border-color: #fff;
}
:where(.css-dev-only-do-not-override-1mqg3i0).ant-btn-default:not(
    :disabled
  ):active {
  color: #fff;
  border-color: #fff;
}

.link {
  background-color: transparent;
  border: none;
  color: #fff;
  cursor: pointer;
  font-size: 14px;
  text-decoration: underline;
  text-align: center;

  &:hover {
    color: #ecf0f1;
  }
}
