/**
 * @since 2023-04-05
 * @author Francesco Parrella
 * @maintainer Francesco Parrella
 * @copyright AlgoTraders, All rights reserved
 */

.panel-settings {
  .settings-content {
    display: flex;
    color: #fff;
    margin-top: 24px;
  }

  .settings-edit-buttons-container {
    height: 40px;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;

    .cancel-button {
      margin-right: 10px;
    }
  }

  .loading-section {
    font-size: 20px;
    margin-top: 40px;
    color: pink;
  }

  .editable-section {
    width: 100%;
    background: #202126;
    margin-top: 24px;
    margin-bottom: 20px;
    border-radius: 8px;
    padding: 20px 30px;
  }

  .see-signals-live-stats {
    text-align: center;
    margin: 20px 0;

    .see-live-button {
      width: 185px;
      height: 50px;
      color: #0f1a2c;
      background: #78bcfb;
      border: none;
      border-radius: 4px;
      cursor: pointer;
    }
  }
}
