/**
 * @since 2023-10-16
 * @author Francesco Parrella
 * @maintainer Francesco Parrella
 * @copyright AlgoTraders, All rights reserved
 */

.notification-checkbox-group-container {
  .setting-description {
    color: white;
    margin-bottom: 40px;
    margin-top: 40px;
  }

  .settings-fields {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    margin-top: 40px;
    margin-bottom: 40px;
  }

  .setting-name {
    user-select: none;
    color: white;
    opacity: 0.5;
    font-size: 14px;
    width: 280px;
    margin-top: 12px;
  }

  .setting-checkbox-value {
    //flex-grow: 1;
    width: 100px;
    color: white;
    font-size: 14px;
  }
}
