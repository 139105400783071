/**
 * @since 2023-08-10
 * @author Francesco Parrella
 * @maintainer Francesco Parrella
 * @copyright AlgoTraders, All rights reserved
 */

.date-range-selector {
  display: flex;

  align-items: center;
  gap: 8px; // Space between the items
}
