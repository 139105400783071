/**
 * @since 2023-06-06
 * @author Francesco Parrella
 * @maintainer Francesco Parrella
 * @copyright AlgoTraders, All rights reserved
 */

.tooltip {
  &-container {
    position: relative;
    display: inline-block;
    cursor: default;
    opacity: 1 !important;
  }

  &-text {
    width: auto;
    height: auto;
    max-width: 600px;
    color: #d2caca;
    text-align: left;
    border-radius: 6px;
    padding: 8px 16px;
    position: fixed;
    font-size: 12px;
    font-weight: normal;
    z-index: 1;
    border: 1px solid #5d9dd9;
    background-color: #2b2c31;
    opacity: 1 !important;
    background-color: #2b2c31 !important;
    visibility: hidden;
    line-height: 1.5;
  }

  &-container:hover .tooltip-text {
    visibility: visible;
  }

  &-text-small {
    margin-left: 10px;
    margin-top: 2px;
    padding: 4px 8px;
    line-height: 2;
    opacity: 1 !important;
    background-color: #2b2c31 !important;
  }

  .visible {
    visibility: visible;
  }
}
