/**
 * @since 2023-10-29
 * @author Francesco Parrella
 * @maintainer Francesco Parrella
 * @copyright AlgoTraders, All rights reserved
 */

.modal-terms-and-conditions {
  width: 800px;
  min-width: 800px;
  max-width: 800px;
  height: 460px;
  min-height: 600px;
  max-height: 600px;
  font-family: Arial, sans-serif;
  text-align: left;
  background-color: #202126;

  .ant-modal-content {
    background-color: #202126;
    border: 3px solid #78bcfb;
    color: white;
    border-radius: 16px;
  }

  .ant-modal-header {
    background-color: #202126;
    color: white;
    padding: 2px;
    margin-bottom: 20px;

    .ant-modal-title {
      font-size: 20px;
      color: white;
    }
  }

  .ant-modal-body {
    background-color: #202126;
    color: white;
    text-align: left;
    max-width: 100%;
    padding-right: 15px;
  }

  .ant-modal-close-x {
    color: white;
  }

  p {
    font-size: 16px;
    line-height: 1.5;
    margin-bottom: 24px;
  }

  .button-group-category {
    background-color: #2b2c31;
    color: white;
    border: none;
    height: 40px;
    max-height: 40px;
    flex-grow: 1;
    font-size: 13px;

    &.Mui-selected {
      background-color: #2b2c31;
      color: #78bcfb;

      &:hover,
      &:active {
        background-color: #2b2c31;
      }
    }

    &:active,
    &:hover {
      background-color: #2b2c31;
    }

    &:hover {
      color: #78bcfb;
    }
  }

  // Newly added styles based on class names
  .text-container {
    width: 730px;
    margin-top: 30px;
    margin-left: 10px;
    margin-right: 20px;
    margin-bottom: 30px;
    align-items: center;
    color: rgba(255, 255, 255, 0.5);
    height: 500px;
    overflow-y: auto;
  }

  .footer-container {
    display: flex;
    justify-content: flex-end;
    width: 100%;
  }
}
