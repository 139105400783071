/**
 * @since 2023-04-05
 * @author Francesco Parrella
 * @maintainer Francesco Parrella
 * @copyright AlgoTraders, All rights reserved
 */

.risk-management-portfolio {
  .clearable-combobox {
    position: relative;
    width: 100%;
    height: 40px;
  }

  .errors-container {
    width: 100%;
    color: red;
    font-size: 14px;
    margin: 10px;
    cursor: default;
  }

  .portfolio-riskmanagement-item {
    display: flex;
    flex-direction: column;
    margin-bottom: 24px;
    cursor: default;
  }

  .portfolio-riskmanagement-row {
    display: flex;
    flex-direction: row;
    color: white;
    height: 30px;
    cursor: default;
  }

  .portfolio-riskmanagement-name {
    width: 260px;
    font-size: 14px;
    color: rgba(255, 255, 255, 0.7);
    cursor: default;
  }

  .portfolio-riskmanagement-value {
    font-size: 14px;
    width: 100%;
    color: white;
    text-align: "right";
  }

  .portfolio-riskmanagement-edit-buttons-container {
    display: flex;
    flex-direction: "row";
    justify-content: flex-end;
  }

  .title-section {
    background: rgba(255, 255, 255, 0.05);
    height: 40px;
    color: rgba(255, 255, 255, 0.5);
    display: flex;
    align-items: center;
    padding: 20px;
    font-size: 14px;
    margin-bottom: 40px;
    cursor: default;
  }

  .settings-group-wrapper {
    width: 100%;
    display: flex;
    margin-top: 30px;
  }

  .settings-group-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    cursor: default;

    &:not(:first-child) {
      margin-left: 40px;
    }
  }

  .table-riskmanagement-container {
    width: 100%;
    display: flex;
    margin-top: 30px;
  }

  .settings-group-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-left: 40px;

    &:first-child {
      margin-left: 0;
    }
  }

  .settings-group-title {
    background: rgba(255, 255, 255, 0.05);
    height: 40px;
    color: rgba(255, 255, 255, 0.5);
    display: flex;
    align-items: center;
    padding: 20px;
    font-size: 14px;
    margin-bottom: 40px;
  }
}
