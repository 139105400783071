/**
 * @since 2023-04-05
 * @author Francesco Parrella
 * @maintainer Francesco Parrella
 * @copyright AlgoTraders, All rights reserved
 */

.editable-section {
  width: 100%;
  background: #202126;
  margin-top: 24px;
  margin-bottom: 20px;
  border-radius: 8px;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 30px;
  padding-right: 30px;
  user-select: none;

  &.edit-mode {
    border: 2px solid #78bcfb;
  }

  .editable-content {
    overflow-x: auto;
    width: 100%;
  }

  .editable-head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #fff;

    div.icon-wrapper {
      display: flex;
      align-items: center;

      & > * {
        font-size: 24px;
        margin-right: 12px;
        cursor: pointer;
        color: #78bcfb;
      }

      span.title-text {
        font-size: 14px;
        margin-right: 0;
        color: #fff;
        cursor: default;
      }
    }

    div.edit-tools {
      display: flex;
      align-items: center;
      color: #78bcfb;

      .edit-icon {
        margin-right: 10px;
        cursor: pointer;
      }

      div.edit-text {
        cursor: pointer;
      }
    }
  }
}
