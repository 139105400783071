/**
 * @since 2023-04-05
 * @author Francesco Parrella
 * @maintainer Francesco Parrella
 * @copyright AlgoTraders, All rights reserved
 */

.table-spreads {
  margin-top: 20px;
  margin-bottom: 10px;
  height: 100%;
  cursor: default;

  .spreads-container {
    width: 100%;
    max-height: 390px;
    overflow-y: auto;
    margin-bottom: 10px;
  }

  .cancel-save-bar {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 40px;
    width: 100%;
  }
}
