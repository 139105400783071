/**
 * @since 2023-05-14 * @author Francesco Parrella
 * @maintainer Francesco Parrella
 * @copyright AlgoTraders, All rights reserved
 */

.styled-icon {
  display: inline-block;
  color: #fff; // Set the default color to white
  position: relative;
  cursor: pointer;

  &:hover {
    color: #78bcfb; // Change the color to #78bcfb on hover

    &[data-tooltip="none"]::after {
      display: none;
    }

    &[data-tooltip]:not([data-tooltip="none"])::after {
      content: attr(data-tooltip);
      position: absolute;
      bottom: 100%;
      left: 50%;
      transform: translateX(-50%);
      background: #000;
      color: #fff;
      padding: 5px;
      border-radius: 5px;
      white-space: nowrap;
    }
  }
}
