/**
 * @since 2023-04-05
 * @author Francesco Parrella
 * @maintainer Francesco Parrella
 * @copyright AlgoTraders, All rights reserved
 */

.table-allocations {
  margin-top: 20px;
  margin-bottom: 10px;
  height: 100%;
  cursor: default;

  .add-allocation-container {
    margin-top: 200px;
    height: 40px;
    width: 100%;
    display: flex;
    justify-content: flex-start; // Align items to the start of the container
    margin-bottom: 20px;
  }

  .add-new-allocation {
    height: 40px;
    width: 100%;
    margin-top: 20px;
    margin-right: 80px;
    border: 1px dashed #78bcfb;
    background: inherit;
    color: #78bcfb;
    cursor: pointer;

    &:hover {
      background: #78bcfb;
      color: #fff;
    }

    &:disabled {
      background: #b875ec;
      color: #fff;
    }
  }
}
