/**
 * @since 2023-04-05
 * @author Francesco Parrella
 * @maintainer Francesco Parrella
 * @copyright AlgoTraders, All rights reserved
 */

.risk-management-strategy {
  .clearable-combobox {
    position: relative;
    width: 100%;
    height: 40px;
  }

  .errors-container {
    width: 100%;
    color: red;
    font-size: 14px;
    margin: 10px;
    cursor: default;
  }

  .riskmanagement-item {
    display: flex;
    flex-direction: column;
    margin-bottom: 24px;
    cursor: default;
  }

  .riskmanagement-row {
    display: flex;
    flex-direction: row;
    color: white;
    height: 30px;
    cursor: default;
  }

  .riskmanagement-name {
    width: 300px;
    font-size: 14px;
    color: rgba(255, 255, 255, 0.7);
    margin-top: 12px;
    margin-left: 12px;
    cursor: default;

    &.not-editing {
      margin-top: 0;
    }
  }

  .riskmanagement-value {
    font-size: 14px;
    width: 100%;
    color: white;
    text-align: right;
    margin-right: 12px;
  }

  .riskmanagement-edit-buttons-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }

  .main-container {
    width: 100%;
    display: flex;
    margin-top: 30px;
  }

  .section {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-left: 40px;
    cursor: default;

    &.first {
      margin-left: 0;
    }

    .section-header {
      background: rgba(255, 255, 255, 0.05);
      height: 40px;
      color: rgba(255, 255, 255, 0.5);
      display: flex;
      align-items: center;
      padding: 20px;
      font-size: 14px;
      margin-bottom: 40px;
    }
  }
}
