/**
 * @since 2023-04-05
 * @author Francesco Parrella
 * @maintainer Francesco Parrella
 * @copyright AlgoTraders, All rights reserved
 */

.clearable-combobox {
  position: relative;
  width: 100%;
  height: 40px;

  &.focused .combobox-container {
    width: 100%;
    height: 100%;
    border: 1px solid #78bcfb;
  }

  &.has-error {
    height: auto;
  }

  .combobox-container {
    padding: 0;
    margin: 0;
    position: absolute;
    left: 0;
    top: 0;
    display: flex;
    width: 100%;
    height: 100%;
    background-color: rgba(26, 26, 26, 0.1);
    box-sizing: border-box;
    overflow: hidden;

    select {
      padding: 10px;
      width: 100%;
      height: 100%;
      outline: none;
      border: none;
      background-color: rgba(255, 255, 255, 0.1);
      color: white;
      box-sizing: border-box;
      appearance: none;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='6' fill='white'%3E%3Cpath d='M0 0h12L6 6z'/%3E%3C/svg%3E");
      background-repeat: no-repeat;
      background-position: right 10px center;
    }

    select option {
      color: white;
      background-color: rgba(26, 26, 26, 0.8);
    }
  }
}
