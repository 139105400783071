/**
@since 2024-01-20
@author Francesco Parrella
@maintainer Francesco Parrella
@copyright AlgoTraders, All rights reserved
*/

.text-message {
  font-size: 16px;
  margin-top: 20px;
  margin-bottom: 20px;
  margin-left: 0px;
  color: pink;
  text-align: left;
  cursor: default;
}
