/**
 * @since 2023-11-25
 * @author Francesco Parrella
 * @maintainer Francesco Parrella
 * @copyright AlgoTraders, All rights reserved
 */

.spread-entry-table {
  width: 99%;
  height: 40px;
  margin-left: 5px;
  margin-bottom: 10px;
  display: flex;
  position: relative;

  .strategy-name-container {
    flex-grow: 1;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.05);
    color: white;
    display: flex;
    align-items: center;
    padding: 0 20px;
    font-size: 14px;
    color: #84ffda;
  }

  .fixed-ratio {
    flex-shrink: 0;
    width: 210px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(255, 255, 255, 0.05);
    color: white;
    font-size: 14px;
  }

  .spread-quantity {
    flex-shrink: 0;
    width: 100px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(255, 255, 255, 0.05);
    color: white;
    font-size: 14px;
  }

  .spread-type {
    flex-shrink: 0;
    width: 200px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(255, 255, 255, 0.05);
    color: white;
    font-size: 14px;
  }
}
