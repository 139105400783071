/**
 * @since 2023-04-05
 * @author Francesco Parrella
 * @maintainer Francesco Parrella
 * @copyright AlgoTraders, All rights reserved
 */

.table-settings-strategy {
  .clearable-combobox {
    position: relative;
    width: 100%;
    height: 40px;
  }

  .errors-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    color: red;
    font-size: 14px;
    margin-top: 120px;
  }

  .error {
    margin-bottom: 10px;
  }

  .warnings-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    color: orange;
    font-size: 14px;
    margin-left: 25px;
  }

  .warning {
    margin-bottom: 10px;
  }

  .setting-item {
    display: flex;
    flex-direction: column;
    margin-bottom: 24px;
  }

  .setting-row {
    display: flex;
    flex-direction: row;
    color: white;
    height: 30px;
  }

  .setting-name {
    user-select: none;
    width: 160px;
    font-size: 14px;
    color: rgba(255, 255, 255, 0.5);
    margin-top: 12px;
    cursor: default;

    &.no-edit-margin {
      margin-top: 0;
    }
  }

  .setting-value {
    font-size: 14px;
    width: 100%;
    color: white;
    cursor: default;
  }

  // New styles for the table settings container
  .table-settings-container {
    width: 100%;
    display: flex;
    margin-top: 30px;
  }

  .table-settings-left,
  .table-settings-right {
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  .table-settings-right {
    margin-left: 40px;
  }
}
