/**
 * @since 2023-04-05
 * @author Francesco Parrella
 * @maintainer Francesco Parrella
 * @copyright AlgoTraders, All rights reserved
 */

.navbar-style {
  .menu-wrapper {
    height: "100%";
    overflow-y: hidden;
  }

  .menu {
    height: "100%";
    background-color: #191a1d !important;
  }

  .ant-menu {
    background: #191a1d !important;
    padding-top: 0px;
    user-select: none;
  }

  .ant-menu-item-selected,
  .ant-menu-item:hover {
    background-color: #191a1d !important;
  }

  .ant-menu-submenu-title:active {
    background-color: inherit !important;
  }

  .ant-menu-submenu-open .ant-menu-item-selected,
  .ant-menu-submenu-open .ant-menu-item:hover {
    background-color: #191a1d !important;
    color: #78bcfb;
  }

  .ant-menu-submenu.my-div {
    background-color: red !important;
  }

  .my-div-menu {
    margin: 0 auto !important;
    width: 200px !important;
    height: 1px !important;
    background-color: #fff;
    opacity: 0.1;
    pointer-events: none;
  }

  .image {
    filter: brightness(70%);
  }

  .nav-toggle-icon {
    position: absolute;
    top: 13px;
    left: 15px;
    cursor: pointer;
    z-index: 1000;
    color: rgb(255, 255, 255, 0.7);
  }

  .nav-toggle-icon:hover {
    color: #78bcfb;
  }

  .nav-menu {
    transition: transform 0.3s ease-in-out;
  }

  .nav-menu-closed {
    transform: translateX(-100%);
  }

  .nav-menu-open {
    transform: translateX(0);
  }
}

.deletion-modal {
  .ant-modal {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .ant-modal-content {
    background-color: #191a1d !important;
    border: 1px solid #78bcfb !important;
    width: 500px !important;
  }

  .ant-modal-header {
    background-color: #191a1d !important;
  }

  .ant-modal-confirm-title {
    color: white !important;
    background-color: #191a1d !important;
  }

  .ant-modal-confirm-body .ant-modal-confirm-content {
    color: white !important;
    background-color: #191a1d !important;
  }

  .ant-modal-confirm-btns {
    display: flex;
    justify-content: flex-end;
    background-color: #191a1d !important;
  }

  .ant-modal-confirm-body {
    padding: 24px;
    border-radius: 4px;
    background-color: #191a1d !important;
  }

  .ant-btn {
    border-color: white !important;
    color: white !important;
    background-color: #191a1d !important;
    user-select: none;
  }

  .ant-btn:hover {
    border-color: #78bcfb !important;
    color: #78bcfb !important;
    background-color: #191a1d !important;
  }
}
