/**
@since 2023-12-21
@author Francesco Parrella
@maintainer Francesco Parrella
@copyright AlgoTraders, All rights reserved
*/

.item-performance-stats {
  .performance-stats-container {
    display: flex;
    width: 100%;
    justify-content: space-between;
    gap: 10px;
  }

  .chart-container,
  .table-container {
    margin-top: 20px;
  }
}
