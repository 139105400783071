/**
 * @since 2023-04-05
 * @author Francesco Parrella
 * @maintainer Francesco Parrella
 * @copyright AlgoTraders, All rights reserved
 */

.month-table {
  overflow-x: auto;
  margin: 0 0 0px;
  padding: 0 38px 0 0;
  color: white;
  cursor: default;
  table {
    width: 100%;
    border-collapse: collapse;
    table-layout: fixed;
    td {
      width: 40px;
      height: 24px;
      background: var(--cell-color);
      text-shadow: 0 0 5px black;
      white-space: nowrap;
      text-align: center;
      padding: 0;
      font-size: 12px;
    }
  }
}

@media screen and (max-width: 1200px) {
  .month-table {
    padding: 0;
    table {
      width: 1200px;
      tr:not(:last-child) {
        td {
          &:first-child,
          &:last-child {
            position: -webkit-sticky;
            position: sticky;
          }
          &:first-child {
            left: 0;
            background: #202126;
          }
          &:last-child {
            right: 0;
            isolation: isolate;
            background: #202126;
            &:before {
              content: "";
              width: 100%;
              height: 38px;
              position: absolute;
              z-index: -1;
              top: 0;
              left: 0;
              background: var(--cell-color);
            }
          }
        }
      }
      tr:last-child {
        td:first-child {
          position: -webkit-sticky;
          position: sticky;
          left: 0;
          background: #202126;
        }
        td:last-child {
          position: -webkit-sticky;
          position: sticky;
          right: 0;
          background: #202126;
        }
      }
    }
  }
}
