/**
@since 2023-10-30
@author Francesco Parrella
@maintainer Francesco Parrella
@copyright AlgoTraders, All rights reserved
*/

.panelHeader {
  .title-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #fff;
    font-size: 26px;
    margin-top: 10px;
    cursor: default; /* Fixed syntax error */
  }

  .title-content {
    display: flex;
    align-items: center;
    justify-self: center;

    > *:first-child {
      margin-right: 16px;
    }
  }

  &.nav-visible .title-header {
    margin-left: 40px;
  }
}
