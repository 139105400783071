/**
* @since 2023-08-01
* @author Francesco Parrella
* @maintainer Francesco Parrella
* @copyright AlgoTraders, All rights reserved
*/

.modal-returns-selector {
  width: 1100px;
  min-width: 1100px;
  max-width: 1100px;
  height: 350px;
  min-height: 350px;
  max-height: 350px;
  font-family: Arial, sans-serif;
  text-align: left;
  background-color: #202126;
  box-sizing: border-box;

  .ant-modal-content {
    background-color: #202126;
    border: 3px solid #78bcfb;
    color: white;
    border-radius: 16px;
  }

  .ant-modal-header {
    background-color: #202126;
    color: white;
    padding: 2px;
    margin-bottom: 20px;

    .ant-modal-title {
      font-size: 20px;
      color: white;
    }
  }

  .ant-modal-body {
    background-color: #202126;
    color: white;
    text-align: left;
  }

  .ant-modal-close-x {
    color: white;
  }

  .file-upload-container {
    margin-top: 40px;
    margin-bottom: 10px;
    display: flex;
    flex-direction: row;
    align-items: left;
    justify-content: normal;
  }

  .file-upload-wrapper {
    display: flex;
    align-items: left;
    margin-bottom: 10px;
  }

  .file-upload-label {
    margin-right: 10px;
    margin-top: 8px;
    color: grey;
    width: 200px;
  }

  .file-upload-box {
    width: 600px;
  }

  .file-input {
    display: none;
  }

  .column-selector-container {
    display: flex;
    align-items: left;
    margin-bottom: 20px;
  }

  .column-label {
    margin-right: 10px;
    margin-top: 8px;
    color: grey;
    width: 200px;
  }

  .multiple-choice-wrapper {
    width: 300px;
  }

  .multiplier-wrapper {
    font-size: 14px;
    width: 100%;
    color: white;
    cursor: default;
    background-color: rgba(255, 255, 255, 0.1);
    height: 40px;
    cursor: default;
    align-items: center; /* Vertically centers the content */
    justify-content: flex-start; /* Aligns the content to the left */
    padding-left: 8px;
    padding-top: 8px;
  }

  .notification-message {
    color: pink;
  }

  .error-message {
    color: red;
  }

  .table-returns-container {
    width: 100%;
    max-height: 300px;
    overflow-y: auto;
  }
}
