/**
 * @since 2023-04-05
 * @author Francesco Parrella
 * @maintainer Francesco Parrella
 * @copyright AlgoTraders, All rights reserved
 */

// Input.scss
.clearable-input {
  position: relative;
  padding: 10px;
  width: 100%;
  background-color: rgba(26, 26, 26, 0.1);
  box-sizing: border-box;
  overflow: hidden;
  outline: none;
  border: none;
  background-color: rgba(255, 255, 255, 0.1);
  color: white;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  cursor: text;

  &.focused {
    border: 1px solid #78bcfb;
  }

  &.has-error {
    height: auto;
  }
}

textarea {
  resize: none;
  cursor: text;
}
