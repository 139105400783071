/**
@since 2023-10-17
@author Francesco Parrella
@maintainer Francesco Parrella
@copyright AlgoTraders, All rights reserved
*/

.tableScoresWithFilters {
  .panelHeader {
    margin-top: 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 36px;
  }

  .buttonGroupRight {
    align-items: right;
  }

  .overallScoreButton {
    color: rgb(179, 168, 253);
    border-color: rgb(179, 168, 253);
    border-width: 1px;
    border-style: solid;
  }
}
