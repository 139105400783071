/**
 * @since 2023-08-02
 * @author Francesco Parrella
 * @maintainer Francesco Parrella
 * @copyright AlgoTraders, All rights reserved
 */

.returns-table {
  width: 100%;
  height: 100%;
  overflow: auto;
  box-shadow: 0 1px 1px 0 #2b2b31;
  margin-bottom: 40px;

  table {
    table-layout: fixed;
    width: 100%;
    font-family: "Maven Pro";
    color: white;
    border-collapse: collapse;
    cursor: default;

    td > span {
      font-family: "Maven Pro";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
    }

    th {
      background: #2b2b31;
      text-align: right;

      /* add border-radius to round the top-left corner */
      &:first-child {
        border-top-left-radius: 4px;
      }

      /* add border-radius to round the top-right corner */
      &:last-child {
        border-top-right-radius: 4px;
      }

      span {
        color: #ffffff;
        opacity: 0.3;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 12px;
      }
    }

    td,
    th {
      height: 34px;
      white-space: nowrap;
      padding: 0 10px;

      /* align text to the left in the first column */
      &:first-child {
        text-align: left;
      }

      /* align text to the right in all the other columns */
      &:not(:first-child) {
        text-align: right;
      }
    }
  }
}
