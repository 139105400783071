/**
  * @since 2024-05-03
  * @author Francesco Parrella
  * @maintainer Francesco Parrella
  * @copyright AlgoTraders, All rights reserved
  */

.modal-settings {
  width: 640px;
  min-width: 640px;
  max-width: 640px;
  height: 700px;
  min-height: 700px;
  max-height: 700px;
  font-family: Arial, sans-serif;
  text-align: left;
  background-color: #202126;

  .ant-modal-content {
    background-color: #202126;
    border: 3px solid #78bcfb;
    color: white;
    border-radius: 16px;
    width: 100%;
    height: 700px;
  }

  .ant-modal-header {
    background-color: #202126;
    color: white;
    padding: 2px;
    margin-bottom: 20px;

    .ant-modal-title {
      font-size: 20px;
      color: white;
    }
  }

  .ant-modal-body {
    background-color: #202126;
    color: white;
    text-align: left;
  }

  .ant-modal-close-x {
    color: white;
  }

  p {
    font-size: 16px;
    line-height: 1.5;
    margin-bottom: 24px;
  }

  .button-group-category {
    background-color: #2b2c31;
    color: white;
    border: none;
    height: 40px;
    max-height: 40px;
    flex-grow: 1;
    font-size: 13px;

    &.Mui-selected {
      background-color: #2b2c31;
      color: #78bcfb;

      &:hover,
      &:active {
        background-color: #2b2c31;
      }
    }

    &:active,
    &:hover {
      background-color: #2b2c31;
    }

    &:hover {
      color: #78bcfb;
    }
  }
}
