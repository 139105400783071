/**
 * @since 2023-04-05
 * @author Francesco Parrella
 * @maintainer Francesco Parrella
 * @copyright AlgoTraders, All rights reserved
 */

.dashboard {
  display: flex;
  background-color: #191a1d;
  height: 100%;
}

.dashboard-main {
  width: 100%;
  //height: 100%;
  background-color: #191a1d;
  overflow-x: hidden;
}

@media (max-width: 800px) {
  .dashboard-main {
    overflow-x: auto;
  }
}

.dashboard-container {
  width: 100%;
  padding-right: 10px;
  padding-left: 10px;
}
