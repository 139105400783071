.buttonBlue {
  display: flex;
  justify-content: center;
  padding: 2em 0;

  &.left {
    justify-content: flex-start;
  }

  &.right {
    justify-content: flex-end;
  }

  &.center {
    justify-content: center;
  }

  .button-blue {
    width: 185px;
    height: 50px;
    color: #0f1a2c;
    background: #78bcfb;
    border: none;
    border-radius: 4px;
    cursor: pointer;

    &:hover {
      background: #78bcfb;
      color: #fff;
    }

    &:disabled {
      background: #b875ec;
      color: #fff;
    }
  }
}
