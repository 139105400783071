
.link {
    background-color: transparent;
    border: none;
    color: #fff;
    cursor: pointer;
    font-size: 14px;
    text-decoration: underline;
    text-align: center;
    margin-top: 14px;

    &:hover {
      color: #ecf0f1;
    }
  }
  