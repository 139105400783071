/**
@since 2023-10-31
@author Francesco Parrella
@maintainer Francesco Parrella
@copyright AlgoTraders, All rights reserved
*/

.toggleButtonGroupSignals {
  button {
    background-color: #2b2c31;
    color: white;
    border: none;
    height: 36px;
    width: 120px;
    flex-grow: 1;
    font-size: 14px;
    padding: 0;
  }

  button.Mui-selected {
    background-color: #2b2c31;
    color: #78bcfb;
  }

  button.Mui-selected:hover {
    background-color: #2b2c31;
  }

  button.Mui-selected:active {
    background-color: #2b2c31;
  }

  button:active {
    background-color: #2b2c31;
  }

  button:hover {
    background-color: #2b2c31;
  }

  .unstyledLink {
    color: white;
    text-decoration: none;
    width: 100%;
    height: 100%;
    justify-content: center;
    display: flex;
    align-items: center;

    button.Mui-selected & {
      color: #78bcfb;
    }
  }

  button.Mui-selected .unstyledLink {
    color: #78bcfb;
  }
}
