/**
 * @since 2024-05-20
 * @author Francesco Parrella
 * @maintainer Francesco Parrella
 * @copyright AlgoTraders, All rights reserved
 */

.indicator-selector {
  .ant-select-selector {
    height: 40px !important;
    display: flex;
    align-items: center;
    justify-self: center;
    border-radius: 0 !important;
    background: rgba(255, 255, 255, 0.05) !important;
    border: none !important;
    padding: 0 8px 0 20px;

    > * {
      display: flex;
      align-items: center;
    }
  }

  .ant-select-selector input {
    display: flex;
    align-items: center;
    justify-content: flex-start !important;
    color: #fff !important;
    width: 100%;
  }

  .ant-select-selection-search {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    padding: 0 !important;
  }
  .ant-select-dropdown {
    background: rgb(32, 33, 38) !important;
    border: 1px solid rgb(132, 255, 218);
    left: 0 !important;
  }

  .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    background-color: rgba(120, 188, 251, 0.2) !important;
    border-radius: 0 !important;
    .ant-select-item-option-content {
      color: #78bcfb !important;
    }
  }

  .ant-select-arrow {
    color: #fff !important;
  }

  .ant-select-item-group {
    color: rgba(255, 255, 255, 0.5) !important;
  }

  .ant-select-item-option-content {
    color: #fff;
  }

  .ant-select-selection-placeholder {
    color: rgb(132, 255, 218) !important;
  }

  .ant-select-selection-item {
    color: #84ffda;
  }

  .ant-select-item-option-active {
    background: rgba(120, 188, 251, 0.2) !important;
    border-radius: 0 !important;
    .ant-select-item-option-content {
      color: #78bcfb !important;
    }
  }

  .ant-select-item-option-content {
    display: flex;
    align-items: center;
  }
}
