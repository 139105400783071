/**
@since 2023-10-30
@author Francesco Parrella
@maintainer Francesco Parrella
@copyright AlgoTraders, All rights reserved
*/

.textCopyright {
  font-size: 14px;
  margin-top: 30px;
  margin-bottom: 30px;
  color: grey;
  text-align: center;
  cursor: default;
}
