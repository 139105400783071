/**
@since 2023-05-09
@author Francesco Parrella
@maintainer Francesco Parrella
@copyright AlgoTraders, All rights reserved
*/

.item-table-scores {
  overflow-x: auto;
  background: inherit !important;
  margin-top: 30px;
  cursor: default;

  .ant-table {
    background-color: #202126;
  }

  .ant-table-thead > tr > th:nth-child(2),
  .ant-table-thead > tr > th:nth-child(3) {
    text-align: right;
  }
  .ant-table-thead > tr > th:nth-child(4) {
    text-align: center;
  }

  .ant-table-thead > tr > th {
    background-color: rgba(255, 255, 255, 0.05) !important;
    color: rgba(255, 255, 255, 0.3) !important;
    border: none !important;
    &::before {
      display: none;
    }
  }

  .ant-table-tbody > tr:hover > td {
    background-color: rgba(255, 255, 255, 0.3) !important;
  }

  .ant-table-row {
    color: #fff;

    td {
      border-bottom: 1px solid rgba(255, 255, 255, 0.05) !important;
    }
  }

  .ant-table-tbody tr td:nth-child(1) {
    text-align: left;
    color: rgb(220, 220, 220);
    width: 600px;
  }

  .ant-table-tbody tr td:nth-child(2) {
    text-align: right;
    color: rgb(220, 220, 220);
    width: 140px;
  }

  .ant-table-tbody tr td:nth-child(3) {
    text-align: right;
    color: rgb(220, 220, 220);
    width: 140px;
  }

  .ant-table-tbody tr td:nth-child(4) {
    text-align: center;
    color: rgb(220, 220, 220);
    width: 220px;
  }

  .ant-table-tbody tr td:nth-child(5) {
    font-style: italic;
    text-align: left;
    color: rgb(220, 220, 220);
  }

  .first-row {
    // background-color: red;
    color: rgb(179, 168, 253);
    font-weight: bold;
    text-align: left;
    td {
      border-bottom: 1px dashed !important;
      span {
        color: rgb(179, 168, 253) !important;
        font-weight: bold;
      }
    }
  }
}
