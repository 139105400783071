/**
  * @since 2024-05-03
  * @author Francesco Parrella
  * @maintainer Francesco Parrella
  * @copyright AlgoTraders, All rights reserved
  */

.modal-password-change {
  width: 500px;
  min-width: 500px;
  max-width: 500px;
  height: 260px;
  min-height: 260px;
  max-height: 260px;
  font-family: Arial, sans-serif;
  text-align: left;
  background-color: #202126;
  margin-top: 150px;

  .ant-modal-content {
    background-color: #202126;
    border: 3px solid #78bcfb;
    color: white;
    border-radius: 16px;
  }

  .ant-modal-header {
    background-color: #202126;
    color: white;
    padding: 2px;
    margin-bottom: 20px;

    .ant-modal-title {
      font-size: 20px;
      color: white;
    }
  }

  .ant-modal-body {
    background-color: #202126;
    color: white;
    text-align: left;
  }

  .ant-modal-close-x {
    color: white;
  }

  p {
    font-size: 16px;
    line-height: 1.5;
    margin-bottom: 24px;
  }

  .setting-item {
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;
  }

  .setting-row {
    display: flex;
    flex-direction: row;
    color: white;
    height: 30px;
  }

  .setting-name {
    user-select: none;
    width: 180px;
    font-size: 14px;
    color: rgba(255, 255, 255, 0.7);
    margin-top: 12px;
    cursor: default;

    &.no-edit-margin {
      margin-top: 0;
    }
  }

  .setting-value {
    font-size: 14px;
    width: 100%;
    color: white;
    margin-left: 30px;
    cursor: default;
  }

  .errors-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    color: red;
    font-size: 14px;
    margin-top: 20px;
  }

  .error {
    margin-bottom: 10px;
  }
}
