/**
 * @since 2023-04-05
 * @author Francesco Parrella
 * @maintainer Francesco Parrella
 * @copyright AlgoTraders, All rights reserved
 */

.ant-select-tag {
  border-radius: 2px;
  background-color: #78bcfb33;
  color: #fff;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  padding: 0 6px;
  margin: 4px 8px 4px 0;
  height: 20px;
  line-height: 18px;
  font-size: 14px;
}

.ant-select-tag-close-icon {
  display: flex;
  align-items: center;
  margin-left: 6px;
  cursor: pointer;
  color: #78bcfb;
  font-size: 12px;
}

.ant-select-tag-close-icon:hover {
  color: #666666;
}

.no-arrow .ant-select-arrow {
  display: none;
}
