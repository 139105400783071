/**
@since 2024-05-31
@autho Francesco Parrella
@maintainer Francesco Parrella
@copyright AlgoTraders, All rights reserved
*/

.table-with-pagination {
  overflow-x: auto;
  cursor: default;
  margin-top: 30px;
  margin-bottom: 30px;

  .ant-table {
    //background-color: #191a1d;
    background-color: inherit;
    color: lightgrey;
  }

  .ant-table-column-sort {
    background-color: #191a1d !important;
  }

  .ant-table-thead > tr > th {
    background-color: rgba(255, 255, 255, 0.05) !important;
    color: lightgrey !important;
    border: none !important;
    line-height: 14px;
    padding-top: 4px;
    padding-bottom: 4px;

    &::before {
      display: none;
    }

    .ant-table-column-sorters {
      line-height: 0px;
      display: flex;
      align-items: center;
    }

    .ant-table-column-title {
      line-height: 0px;
    }

    .ant-table-column-sorter {
      margin-left: 4px;
    }
  }

  .ant-table-thead > tr > th.ant-table-column-has-sorters {
    .ant-table-column-sorter-up.active,
    .ant-table-column-sorter-down.active {
      color: #78bcfb !important;
    }
  }

  .ant-table-tbody > tr:hover > td {
    background-color: rgba(255, 255, 255, 0.3) !important;
  }

  .ant-table-row {
    color: lightgrey;
    line-height: 12px;

    td {
      border-bottom: 1px solid rgba(255, 255, 255, 0.05) !important;
    }
  }

  .ant-table-placeholder {
    background-color: rgba(255, 255, 255, 0.05) !important;
    color: lightgrey !important;

    td {
      border-bottom: 1px solid rgba(255, 255, 255, 0.05) !important;
    }
  }

  .ant-table-placeholder .ant-empty .ant-empty-description {
    color: lightgrey !important;
  }

  .right-aligned-cell {
    text-align: right !important;
  }

  .ant-pagination-simple {
    background-color: #191a1d !important;
    color: lightgrey;

    .ant-pagination-simple-pager {
      input {
        background-color: #191a1d !important;
        border: 1px solid rgba(255, 255, 255, 0.1) !important;
        color: lightgrey;
      }
    }

    .ant-pagination-prev,
    .ant-pagination-next {
      background-color: #191a1d;
      border-color: rgba(255, 255, 255, 0.1);
      color: lightgrey;
    }

    .ant-pagination-prev .ant-pagination-item-link,
    .ant-pagination-next .ant-pagination-item-link {
      color: lightgrey;
    }
  }

  .ant-pagination-options {
    background-color: #191a1d !important;
    border: 1px solid rgba(255, 255, 255, 0.1) !important;
    color: lightgrey !important;
  }

  .ant-select-selector {
    background-color: #191a1d !important;
    border: 1px solid rgba(255, 255, 255, 0.1) !important;
    color: lightgrey !important;
  }

  .ant-select-arrow {
    color: lightgrey !important;
  }

  .ant-select-dropdown {
    background-color: #191a1d !important;
    color: lightgrey !important;

    input {
      background-color: #191a1d !important;
      border: 1px solid rgba(255, 255, 255, 0.1) !important;
      color: lightgrey;
      pointer-events: none;
    }
  }

  .ant-select-item {
    background-color: #191a1d !important;
    color: lightgrey !important;

    &-active {
      background-color: rgba(255, 255, 255, 0.1) !important;
    }

    &-selected {
      background-color: rgba(255, 255, 255, 0.1) !important;
    }
  }
}
