/**
  * @since 2023-04-05
  * @author Francesco Parrella
  * @maintainer Francesco Parrella
  * @copyright AlgoTraders, All rights reserved
  */

.modal-universe-selector {
  width: 1100px;
  min-width: 1100px;
  max-width: 1100px;
  height: 460px;
  min-height: 460px;
  max-height: 460px;
  font-family: Arial, sans-serif;
  text-align: left;
  background-color: #202126;

  .ant-modal-content {
    background-color: #202126;
    border: 3px solid #78bcfb;
    color: white;
    border-radius: 16px;
  }

  .ant-modal-header {
    background-color: #202126;
    color: white;
    padding: 2px;
    margin-bottom: 20px;

    .ant-modal-title {
      font-size: 20px;
      color: white;
    }
  }

  .ant-modal-body {
    background-color: #202126;
    color: white;
    text-align: left;
  }

  .ant-modal-close-x {
    color: white;
  }

  p {
    font-size: 16px;
    line-height: 1.5;
    margin-bottom: 24px;
  }
}
