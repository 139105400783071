/**
 * @since 2023-04-05
 * @author Francesco Parrella
 * @maintainer Francesco Parrella
 * @copyright AlgoTraders, All rights reserved
 */

.purple-select {
  .ant-select-selector {
    height: 40px !important;
    display: flex;
    align-items: center;
    justify-self: center;
    border-radius: 0 !important;
    background: rgba(255, 255, 255, 0.05) !important;
    border: none !important;
  }

  .ant-select-selector input {
    display: flex;
    align-items: center;
    justify-self: center;
    color: #fff !important;
  }

  .ant-select-selection-search {
    display: flex;
    align-items: center;
    justify-self: center;
  }

  .ant-select-dropdown {
    background: rgb(32, 33, 38) !important;
    border: 1px solid rgb(179, 168, 253);
    // display: block;
    left: 0 !important;
  }

  .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    background-color: rgba(120, 188, 251, 0.2) !important;
    border-radius: 0 !important;
    .ant-select-item-option-content {
      color: #78bcfb !important;
    }
  }

  .ant-select-arrow {
    color: #fff !important;
  }

  .ant-select-item-group {
    color: rgba(255, 255, 255, 0.5) !important;
  }

  .ant-select-item-option-content {
    color: #fff;
  }

  .ant-select-selection-placeholder {
    color: rgb(179, 168, 253) !important;
  }

  .ant-select-selection-item {
    color: rgb(179, 168, 253);
  }

  .ant-select-item-option-active {
    background: rgba(120, 188, 251, 0.2) !important;
    border-radius: 0 !important;
    .ant-select-item-option-content {
      color: #78bcfb !important;
    }
  }
}

.grey-select {
  .ant-select-selector {
    height: auto !important;
    padding: 10px 8px;
    display: flex;
    align-items: center;
    justify-self: center;
    border-radius: 0 !important;
    background: rgba(255, 255, 255, 0.05) !important;
    border: 1px solid rgba(255, 255, 255, 0.5) !important;
  }

  .ant-select-selector input {
    display: flex;
    align-items: center;
    justify-self: center;
    color: #fff !important;
  }

  .ant-select-selection-search {
    display: flex;
    align-items: center;
    justify-self: center;
  }

  .ant-select-dropdown {
    background: rgb(32, 33, 38) !important;
    border: 1px solid rgba(255, 255, 255, 0.5);
    // display: block;
    left: 0 !important;
  }

  .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    background-color: rgba(120, 188, 251, 0.2) !important;
    border-radius: 0 !important;
    .ant-select-item-option-content {
      color: #78bcfb !important;
    }
  }

  .ant-select-arrow {
    color: #fff !important;
  }

  .ant-select-item-group {
    color: rgba(255, 255, 255, 0.5) !important;
  }

  .ant-select-item-option-content {
    color: #fff;
  }

  .ant-select-selection-placeholder {
    color: rgba(255, 255, 255, 0.5) !important;
  }

  .ant-select-selection-item {
    background-color: rgba(120, 188, 251, 0.2) !important;
    color: #fff;
    .ant-select-selection-item-remove {
      color: #78bcfb;
    }
  }

  .ant-select-item-option-active {
    background: rgba(120, 188, 251, 0.2) !important;
    border-radius: 0 !important;
    .ant-select-item-option-content {
      color: #78bcfb !important;
    }
  }
}

.edit-buttons-container {
  height: 40px;
  width: 100%;
  display: flex;
  justify-content: space-around;
  margin-top: 20px;
  margin-bottom: 20px;
}

.add-new-rule {
  margin-top: 20px;
  height: 40px;
  width: 80%;
  border: 1px dashed #78bcfb;
  background: inherit;
  color: #78bcfb;
  cursor: pointer;
}

.errors-container {
  width: 90%;
  display: flex;
  flex-direction: column;
  color: red;
  font-size: 14px;
  margin-left: 24px;
  margin-top: 30px;
}
