/**
@since 2024-05-26
@author Francesco Parrella
@maintainer Francesco Parrella
@copyright AlgoTraders, All rights reserved
*/

.item-table-performance {
  width: 100%;

  .ant-table-thead > tr:last-child > th {
    display: none;
  }
}
