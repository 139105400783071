/**
 * @since 2023-09-27
 * @author Francesco Parrella
 * @maintainer Francesco Parrella
 * @copyright AlgoTraders, All rights reserved
 */

.table-settings-api-container {
  .setting-description {
    color: white;
    margin-bottom: 20px;
    margin-top: 40px;
  }

  .settings-fields {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    margin-top: 40px;
    margin-bottom: 40px;
  }

  .settings-container {
    display: flex;
    align-items: center;
  }

  .setting-name {
    user-select: none;
    font-size: 14px;
    color: rgba(255, 255, 255, 0.7);
    width: 100px;
    cursor: default;
  }

  .setting-value {
    width: 720px;
    font-size: 14px;
    color: white;
    cursor: default;
  }

  .edit-container {
    display: flex;
    flex-direction: column;
  }

  .button-container {
    margin-top: 20px;
  }

  .refresh-button {
    padding: 10px;
    border: none;
    background-color: #78bcfb;
    color: black;
    border-radius: 4px;
    width: 80px;
    height: 100%;
    cursor: pointer;
  }

  .refresh-button:hover {
    background: #78bcfb;
    color: #fff;
  }

  .refresh-button:disabled {
    background: #b875ec;
    color: #fff;
  }
}
