.error-text {
    font-size: 3vh;
    font-weight: bold;
    color: gray;
    text-align: center;
    top: 50%;
    left: 50%;
    position: fixed;
    transform: translate(-50%, -100%);
}

.dashboard-nav-text {
    color: #bcb021;
    margin: 20px;
    font-size: 2vh;
    display: block;
}
