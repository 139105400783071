/**
 * @since 2023-04-05
 * @author Francesco Parrella
 * @maintainer Francesco Parrella
 * @copyright AlgoTraders, All rights reserved
 */

.search-box {
  &-container {
    position: relative;
    width: 100%;
    overflow: hidden;
  }

  &-input {
    padding: 10px;
    padding-left: 40px;
    width: 100%;
    background-color: rgba(26, 26, 26, 0.1);
    box-sizing: border-box;
    overflow: hidden;
    outline: none;
    border: none;
    background-color: rgba(255, 255, 255, 0.1);
    color: white;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
    cursor: text;

    &.focused {
      border: 1px solid #78bcfb;
    }

    &.has-error {
      height: auto;
    }
  }

  &-icon {
    position: absolute;
    top: 50%;
    left: 10px;
    transform: translateY(-50%);
    color: rgb(121, 115, 115);
  }
}
