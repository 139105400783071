/**
 * @since 2024-05-04
 * @author Francesco Parrella
 * @maintainer Francesco Parrella
 * @copyright AlgoTraders, All rights reserved
 */

.label-box {
  color: white;
  font-family: inherit;
  font-size: inherit;
  //margin-left: 10px;
  margin-top: 10px;
}
