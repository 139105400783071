/**
@since 2023-08-10
@author Francesco Parrella
@maintainer Francesco Parrella
@copyright AlgoTraders, All rights reserved
*/

.item-table-dataframe {
  overflow-x: auto;
  background: inherit !important;
  margin-top: 0px;
  margin-bottom: 0px;
  cursor: default;
  //width: 100%;

  .ant-table {
    background-color: #202126;
    width: 400px;
  }

  .ant-table-thead > tr > th {
    background-color: rgba(255, 255, 255, 0.05) !important;
    color: rgba(255, 255, 255, 0.3) !important;
    border: none !important;
    line-height: 8px;
    text-align: right;

    &:first-child {
      text-align: left;
    }

    &::before {
      display: none;
    }
  }

  .ant-table-tbody > tr:hover > td {
    background-color: rgba(255, 255, 255, 0.3) !important;
  }

  .ant-table-tbody > tr > td {
    text-align: right;
  }

  .ant-table-tbody > tr > td:first-child {
    text-align: left;
  }

  .ant-table-row {
    color: #fff;

    line-height: 4px;
    td {
      border-bottom: 1px solid rgba(255, 255, 255, 0.05) !important;
    }
  }

  .ant-table-placeholder {
    background-color: rgba(255, 255, 255, 0.05) !important;
    td {
      border-bottom: 1px solid rgba(255, 255, 255, 0.05) !important;
    }
  }
  .ant-table-placeholder .ant-empty .ant-empty-description {
    color: #fff !important;
  }
}
