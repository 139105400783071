/**
@since 2023-10-30
@author Francesco Parrella
@maintainer Francesco Parrella
@copyright AlgoTraders, All rights reserved
*/

.text-wait {
  font-size: 16px;
  margin-top: 20px;
  margin-bottom: 20px;
  margin-left: 0px;
  color: pink;
  text-align: left;
  cursor: default;
}
