/**
@since 2023-12-22
@author Francesco Parrella
@maintainer Francesco Parrella
@copyright AlgoTraders, All rights reserved
*/

.item-indicator-stats {
  .indicator-stats-container {
    display: flex;
  }

  .chart-container {
    margin-top: 20px;
  }
}
