/**
  * @since 2024-05-06
  * @author Francesco Parrella
  * @maintainer Francesco Parrella
  * @copyright AlgoTraders, All rights reserved
  */

.panel-subscription {
}

.cancel-modal {
  margin-top: 200px;
  .ant-modal {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1000;
  }

  .ant-modal-content {
    background-color: #191a1d !important;
    border: 1px solid #78bcfb !important;
    width: 420px !important;
    margin: 0;
  }

  .ant-modal-header {
    background-color: #191a1d !important;
  }

  .ant-modal-confirm-title {
    color: white !important;
    background-color: #191a1d !important;
  }

  .ant-modal-confirm-body .ant-modal-confirm-content {
    color: white !important;
    background-color: #191a1d !important;
  }

  .ant-modal-confirm-btns {
    display: flex;
    justify-content: flex-end;
    background-color: #191a1d !important;
  }

  .ant-modal-confirm-body {
    padding: 24px;
    border-radius: 4px;
    background-color: #191a1d !important;
  }

  .ant-btn {
    border-color: white !important;
    color: white !important;
    background-color: #191a1d !important;
    user-select: none;
  }

  .ant-btn:hover {
    border-color: #78bcfb !important;
    color: #78bcfb !important;
    background-color: #191a1d !important;
  }
}
