/**
 * @since 2023-05-12
 * @author Francesco Parrella
 * @maintainer Francesco Parrella
 * @copyright AlgoTraders, All rights reserved
 */

.display-box {
  position: relative;
  width: 100%;
  cursor: pointer;

  &:hover .display-box__container {
    border: 1px solid #78bcfb;
  }

  &.focused .display-box__container {
    width: 100%;
    height: 100%;
    border: 1px solid #78bcfb;
  }

  &.has-error {
    height: auto;
  }

  &__container {
    padding: 0;
    margin: 0;
    position: absolute;
    left: 0;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.1);
    box-sizing: border-box;
    overflow: hidden;
  }

  &__content {
    padding: 10px;
    overflow: auto;
    white-space: pre-wrap;
    background-color: rgba(26, 26, 26, 0.1);
    width: 100%;
    color: white;
    box-sizing: border-box;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
  }

  &__icon {
    margin-right: 10px;
  }
}
