/**
 * @since 2023-04-05
 * @author Francesco Parrella
 * @maintainer Francesco Parrella
 * @copyright AlgoTraders, All rights reserved
 */

@import url("https://fonts.googleapis.com/css2?family=Maven+Pro:wght@400;500;600&display=swap");

html,
body {
  background-color: #191a1d;
  width: 100%;
  height: 100%;
  margin: 0;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: Maven Pro;
}

.container {
  width: 100%;
  overflow: auto;
  padding-right: 0px;
  padding-left: 0px;
}

.app-container {
  user-select: none;
  width: 100%;
  //min-height: 100vh;
  //height: 100vh;
}
