/**
 * @since 2023-05-17
 * @author Francesco Parrella
 * @maintainer Francesco Parrella
 * @copyright AlgoTraders, All rights reserved
 */

.save-cancel-buttons-container {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;

  button:not(:last-child) {
    margin-right: 20px;
  }

  .cancel-button {
    border: 1px solid #78bcfb;
    background: inherit;
    color: #78bcfb;
    border-radius: 4px;
    width: 100px;
    height: 40px;
    cursor: pointer;
  }

  .save-button {
    border: none;
    width: 100px;
    height: 40px;
    background: #78bcfb;
    color: black;
    border-radius: 4px;
    cursor: pointer;
  }

  .save-button:hover {
    background: #78bcfb;
    color: #fff;
  }

  .save-button:disabled {
    background: #b875ec;
    color: #fff;
  }
}
