/**
 * @since 2023-10-05
 * @author Francesco Parrella
 * @maintainer Francesco Parrella
 * @copyright AlgoTraders, All rights reserved
 */

.table-settings-textmessage-container {
  .setting-description {
    color: white;
    margin-bottom: 20px;
    margin-top: 40px;
  }

  .settings-fields {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    margin-top: 40px;
    margin-bottom: 40px;
  }

  .setting-name {
    user-select: none;
    color: rgba(255, 255, 255, 0.7);
    font-size: 14px;
    width: 280px;
    margin-top: 12px;
    cursor: default;
  }

  .setting-input-value {
    flex-grow: 1;
    color: white;
    font-size: 14px;
    cursor: default;
  }

  .setting-checkbox-value {
    //flex-grow: 1;
    width: 100px;
    color: white;
    font-size: 14px;
    cursor: default;
  }

  .button-container {
    margin-top: 20px;
  }

  .test-textmessage-button {
    padding: 10px;
    border: none;
    background-color: #78bcfb;
    color: black;
    border-radius: 4px;
    width: 170px;
    cursor: pointer;
  }

  .test-textmessage-button:hover {
    background: #78bcfb;
    color: #fff;
  }

  .test-textmessage-button:disabled {
    background: #b875ec;
    color: #fff;
  }

  .errors-container {
    margin-left: 0px;
    margin-top: -20px;
    color: red;
    font-size: 14px;
  }
}
