/**
@since 2023-05-13
@author Francesco Parrella
@maintainer Francesco Parrella
@copyright AlgoTraders, All rights reserved
*/

.panel-instruments {
  overflow-x: auto;
  background-color: #202126;
  margin-top: 30px;

  .ant-table {
    background-color: #202126;
    text-align: left;
    height: 300px;
    max-height: 300px;
    min-height: 300px;

    .ant-table-tbody .ant-table-column-sort,
    .ant-table-tbody .ant-table-filter-active {
      background-color: #202126 !important;

      /* Define hover state for filter column */
      &:hover {
        background-color: rgba(255, 255, 255, 0.3) !important;
      }
    }

    .ant-table-tbody > tr:hover {
      background-color: rgba(255, 255, 255, 0.3) !important;
    }

    .ant-table-tbody > tr:hover > td,
    .ant-table-tbody > tr:hover > td.ant-table-column-sort,
    .ant-table-tbody > tr:hover > td.ant-table-filtered-cell,
    .ant-table-tbody > tr:hover > td.ant-table-filter-active {
      background-color: inherit !important;
    }

    .ant-table-tbody > tr > td.ant-table-filter-active {
      background-color: rgba(255, 255, 255, 0.05) !important;
    }

    .ant-table-thead > tr > th {
      background-color: rgba(255, 255, 255, 0.05) !important;
      color: rgba(255, 255, 255, 0.3) !important;
      border-bottom: 1px solid rgba(255, 255, 255, 0.05) !important;
      height: 36px !important;
      line-height: 30px !important;
      padding: 2px !important;

      &::before {
        display: none;
      }
    }

    .ant-table-row > td {
      background-color: rgba(255, 255, 255, 0.05) !important;
      color: #fff;
      text-align: left;
      height: 24px !important;
      line-height: 24px !important;
      padding: 4px !important;
      box-sizing: border-box;
      border: none !important;
      border-bottom: 1px solid rgba(255, 255, 255, 0.05) !important;

      &::before {
        display: none;
      }
    }

    .ant-table-cell-scrollbar {
      background-color: rgba(255, 255, 255, 0.05);
      border: none !important;
      color: rgba(255, 255, 255, 0.05);
      box-sizing: border-box;
      display: none;
      &::before {
        display: none;
      }
    }

    .ant-table-placeholder {
      background-color: rgba(255, 255, 255, 0.05) !important;
      td {
        border-bottom: 1px solid rgba(255, 255, 255, 0.05) !important;
      }
    }
    .ant-table-placeholder .ant-empty .ant-empty-description {
      color: #fff !important;
    }
  }

  .search-controls {
    background-color: #202126;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 40px;
    max-height: 40px;
  }

  .input-search {
    margin-left: auto;
    color: white;
    background-color: #202126;
    font-size: 14px;
    border: none;
  }

  .button-group-security-types {
    background-color: #2b2c31;
    color: white;
    border: none;
    height: 40px;
    max-height: 40px;
    flex-grow: 1;
    font-size: 13px;

    &.Mui-selected {
      background-color: #2b2c31;
      color: #78bcfb;

      &:hover,
      &:active {
        background-color: #2b2c31;
      }
    }

    &:active,
    &:hover {
      background-color: #2b2c31;
    }

    &:hover {
      color: #78bcfb;
    }
  }
}
