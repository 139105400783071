/**
 * @since 2023-04-05
 * @author Francesco Parrella
 * @maintainer Francesco Parrella
 * @copyright AlgoTraders, All rights reserved
 */

.table-settings-premium {
  .setting-item {
    display: flex;
    flex-direction: column;
    margin-bottom: 24px;
  }

  .setting-row {
    display: flex;
    flex-direction: row;
    color: white;
    height: 30px;
  }

  .setting-name,
  .setting-value {
    font-size: 14px;
    width: 100%;
    color: white;
    cursor: default;
  }

  .setting-name {
    user-select: none;
    width: 160px;
    color: rgba(255, 255, 255, 0.7);
    cursor: default;
  }

  .main-container {
    width: 100%;
    display: flex;
    margin-top: 30px;

    .left-settings,
    .right-settings {
      width: 100%;
      display: flex;
      flex-direction: column;

      &.right-settings {
        margin-left: 40px;
      }
    }
  }
}
