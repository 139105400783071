/**
 * @since 2024-05-31
 * @author Francesco Parrella
 * @maintainer Francesco Parrella
 * @copyright AlgoTraders, All rights reserved
 */

.bar-actions-analysis {
  .download-item {
    display: flex;
    align-items: center;
    padding: 3px;
    cursor: pointer;
    transition: color 0.3s ease;
    color: white;
    font-size: 15px;
    margin-bottom: 16px;

    .download-icon {
      font-size: 1.5em;
      margin-right: 16px;
      color: currentColor;
    }

    &:hover {
      color: #78bcfb;
    }
  }
}
