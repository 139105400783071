.rule-container {
  margin-bottom: 40px;

  .entry-table {
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-bottom: 20px;
    position: relative;
    cursor: default;
  }

  .indicator-selector {
    background: rgba(255, 255, 255, 0.05);
    width: 100%;
    height: 40px;
    color: #84ffda;
    display: flex;
    align-items: center;
    font-size: 14px;
  }

  .indicator-selector-text {
    padding: 0 8px 0 20px;
  }

  .indicator-container {
    display: flex;
    flex-direction: column;
    flex: 1;
  }

  .full-width {
    flex: auto;
  }

  .operator-container {
    display: flex;
    flex-direction: column;
    flex: none;
    width: 8%;
    margin: 0 10px;
  }

  .delete-button {
    width: 100px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .close-icon {
    font-size: 20px;
    color: #78bcfb;
    cursor: pointer;
  }
}
